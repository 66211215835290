import ky from 'ky'
import handleRefreshTokenIfExpired from 'src/api/utils/handleRefreshTokenIfExpired'
import { ENV } from 'src/utils/env'
import { getWindowNextDataProps } from 'src/utils/get-window-next-data'

const { token, slug, installationId, version } = getWindowNextDataProps()

export let bidlClient = ky.create({
  prefixUrl: ENV.BIDL_API,
  retry: 0,
  timeout: 120000,
  headers: {
    Authorization: token ? `Bearer ${token}` : undefined,
    'App-Company': (slug as string) ?? undefined,
    'App-Name': ENV.APP_URL,
    'App-Version': (version as string) ?? undefined,
    'App-Installation-Id': (installationId as string) ?? undefined,
  },

  hooks: {
    beforeRequest: [handleRefreshTokenIfExpired],
    afterResponse: [
      async (_request, _options, response) => {
        if (!response.ok) {
          try {
            const body = await response.clone().text()

            console.error('src/api - api request failed', {
              url: response.url,
              status: response.status,
              requestUrl: _request.url,
              body,
            })

            response.payload = JSON.parse(body)
          } catch (err) {
            console.error('src/api - api request failed', {
              url: response.url,
              status: response.status,
            })
          }
        }

        return response
      },
    ],
  },
})

export const updateBidlClient = (config = {}) => {
  bidlClient = bidlClient.extend(config)
}

export const updateBidlClientHeader = (key: string, value: string) => {
  updateBidlClient({
    headers: { [key]: value },
  })
}

export const updateBidlToken = (token?: string) =>
  updateBidlClient({ headers: { Authorization: token ? `Bearer ${token}` : undefined } })
